<template>
  <div
    class="main-content page_bg_grey blank-aside-js"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <div class="content-margin">
      <banner-section location="shaniv_cart-top"/>
      <div class="cart-content m-t-30">
        <h2 v-if="orderedItems.length > 0" class="title fs23 m-b-20">
          {{ $t("CART.ORDER_NUMBER") }} 
          {{ $t("CART.DONE_SUCCESSFULLY") }}
        </h2>
        <div v-if="toPayItems.length > 0" class="d-flex align-items-center flex-column">
          <h2  class="title text-center fs23 m-b-20">
            {{ $t("CART.PAYMENT_TO_TXT") }} 
          </h2>
          <div class="payment-requirement">
            <PaymentSummary>
              <template v-slot:actionBtn>
                <router-link
                  :to="{
                    name: 'OrderPayment',
                    params: { id: orderId },
                  }"
                  class="primary-btn"
                >
                  {{ $t("CART.PRESS_TO_PAYMENT") }} 
                </router-link>
              </template>
            </PaymentSummary>
          </div>
        </div>
        <div v-if="orderDocs && orderDocs.length > 0" class="d-flex justify-content-between">
          <div class="products-container small-width">
            Generated order docs:

            <a v-for="(order_doc, index) in orderDocs" :key="order_doc.id" :href="order_doc.url" target="_blank">
              <img src="/assets/img/icons/file-chart-line-light.svg" :alt="order_doc.type" :title="order_doc.type" class="download-icon pointer" />
            </a>
          </div>
        </div>
        <div v-if="orderedItems.length > 0" class="d-flex justify-content-between">
          <div class="products-container small-width">
            <CartTable :tableData="orderedItems" :isEditQty="false"></CartTable>
          </div>
          <div class="summary-container">
            <div class="summary-box">
              <div class="supplier-container">
                <div class="credit-limit d-flex align-items-center justify-content-between">
                  <img
                    class="company-logo"
                    src="/new-assets/img/2סאסאטק.png"
                    alt=""
                  />
                  <img
                    class="company-logo"
                    src="/new-assets/img/shaniv-logo.png"
                    alt=""
                  />
                  <h2 class="fs20">{{ $t('CART.ORDER_SUMMARY') }}</h2>
                </div>

                <div class="discount">
                  <div class="flex fs14">
                    <p>{{ $t('CART.ORDER_VALUE') }}: </p>
                    <p class="weight-700">
                      ₪{{ calcOrderPrice }}
                    </p>
                  </div>
                  <div v-if="calcOrderDiscountAmount && calcOrderDiscountAmount > 0" class="flex fs14 color-green3 weight-700">
                    <p>{{ $t('CART.SAVINGS') }}: </p>
                    <p>₪{{ calcOrderDiscountAmount }}</p>
                  </div>
                </div>
                <div class="order-summary fs14">
                  <div class="flex">
                    <div>
                      <p class="m-b-20">{{ $t('CART.ORDER_SUMMARY') }}: </p>
                      <p v-if="customerData && Object.values(customerData).length" class="m-b-5 cursor-pointer" @click="openShanivCustomerAgentModal">
                        {{ $t('GIFTCARD_PAYMENT_RESULT.AGENT') }}:
                        <span><b>{{ customerAgentNames() }}</b></span>
                      </p>
                      <p class="m-b-5">{{ $t('CART.DAY_ORDER_ARRIVES') }}: <b>{{ dueDate }}</b></p>
                    </div>
                    <div class="fs18 weight-700">
                      <p>₪{{ calcOrderDiscountPrice }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ShanivCustomerAgentModal></ShanivCustomerAgentModal>

    <loading
      :active.sync="createOrderLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CartTable from '../../components/cart/CartTable';
import PaymentSummary from '../../components/cart/PaymentSummary';

import ShanivCustomerAgentModal from "../../components/ShanivCustomerAgentModal.vue";

import CartMixin from '../../utility/cart_mixin';
import BannerSection from "../../components/BannerSection";

export default {
  name: 'OrderSuccess',
  mixins: [CartMixin],
  components: {
    BannerSection,
    CartTable,
    ShanivCustomerAgentModal,
    PaymentSummary
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      createOrderLoading: (state) => state.cartSystem.createOrderLoading,
      newOrder: (state) => state.cartSystem.newOrder,
      shanivOrderData: (state) => state.cartSystem.shanivOrderData
    }),
    orderData() {
      let orderData = null;
      if (this.shanivOrderData
        && this.shanivOrderData.id
        && this.shanivOrderData.id == this.orderId) {
        orderData = {...this.shanivOrderData};
      }

      return orderData;
    },
    orderedItems() {
      let orderedItems = [];

      if (this.orderData && this.orderData.ShanivOrderItems) {
        orderedItems = this.orderData.ShanivOrderItems.filter(
          soi =>  (!soi.payment_required || soi.paid) && !soi.failed
        );
      }

      return orderedItems;
    },
    toPayItems() {
      let toPayItems = [];

      if (this.orderData && this.orderData.ShanivOrderItems) {
        toPayItems = this.orderData.ShanivOrderItems.filter(
          soi => !soi.BOOKNUM && soi.payment_required && !soi.paid && !soi.failed
        );
      }

      return toPayItems;
    },
    orderDocs() {
      let orderDocs = [];

      if (this.orderData && this.orderData.ShanivOrderDocs) {
        orderDocs = [...this.orderData.ShanivOrderDocs];
      }

      return orderDocs;
    },
    dueDate() {
      let dueDate = null;

      if (this.orderedItems && this.orderedItems.length > 0 && this.orderedItems[0].DUEDATE) {
        dueDate = moment(this.orderedItems[0].DUEDATE).format('DD/MM/YYYY dddd');
      } else {
        dueDate = 'N/A';
      }

      return dueDate;
    },
    agent() {
      let agent = this.orderData && this.orderData.user_id ? this.orderData.user_id : null;
      agent = agent || 'N/A';

      return agent;
    },
    calcOrderDiscountPrice() {
      let price = 0;
      if (!this.orderedItems) {
        return price;
      }

      for (let i in this.orderedItems) {
        if (this.orderedItems[i].failed) {
          continue;
        }
        this.orderedItems[i].ShanivOrderItemPackages.forEach(el => {
          price += el.quantity * el.DISCOUNT_VATPRICE;
        });
        this.orderedItems[i].ShanivOrderItemProducts.forEach(el => {
          price += el.quantity * el.DISCOUNT_VATPRICE * (el.qtySteps > 0 ? el.qtySteps : 1);
        });
      }

      price = Math.round((price + Number.EPSILON) * 100) / 100;
      return price;
    },
    calcOrderPrice() {
      let price = 0;
      if (!this.orderedItems) {
        return price;
      }

      for (let i in this.orderedItems) {
        if (this.orderedItems[i].failed) {
          continue;
        }
        this.orderedItems[i].ShanivOrderItemPackages.forEach(el => {
          price += el.quantity * el.VATPRICE;
        });
        this.orderedItems[i].ShanivOrderItemProducts.forEach(el => {
          price += el.quantity * el.VATPRICE * (el.qtySteps > 0 ? el.qtySteps : 1);
        });
      }

      price = Math.round((price + Number.EPSILON) * 100) / 100;
      return price;
    },
    calcOrderDiscountAmount() {
      let amount = 0;

      if (this.calcOrderPrice && this.calcOrderDiscountPrice) {
        amount = this.calcOrderPrice - this.calcOrderDiscountPrice;
      }

      amount = Math.round((amount + Number.EPSILON) * 100) / 100;
      return amount;
    },
    orderId() {
      return this.$route.params.id;
    }
  },
  async created() {
    let orderData = await this.getShanivOrderData({id: this.orderId}).then(data => {
      return data;
    });

    if (orderData && orderData.response && orderData.response.status != 200) {
      // todo: show an error here
      this.$router.push({ name: 'CartOrderHistory'});
    }
  },
  methods: {
    ...mapActions('cartSystem', {
      getShanivOrderData: 'getShanivOrderData',
    }),
    openShanivCustomerAgentModal() {
      this.$modal.show('shanivCustomerAgentModal');
    },
  },
};
</script>

<style lang="scss" scoped>
.cart-content {
  padding-bottom: 50px;
  .products-container {
    &.small-width {
      width: calc(70% - 30px);
    }
  }
  .company-logo {
    max-width: 100px;
    width: 100%;
    height: auto;
  }
  .payment-requirement {
    .summary-container {
      width: 100%;
      max-width: 500px;
      margin-top: 10px;
    }
  }
  .summary-container {
    width: 30%;
    margin-top: 60px;
    .summary-box {
      border: 1px solid #707070;
      border-radius: 6px;
      padding: 10px;

      .supplier-container {
        .flex {
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          margin-bottom: 0.5rem;
        }

        .credit-limit,
        .discount {
          border-bottom: 1px solid #707070;
          padding: 8px 15px;
        }

        .discount,
        .order-summary {
          padding: 8px 15px 0;
        }
      }
    }
  }
}
</style>
<template>
  <div class="summary-container">
    <div class="summary-box">
      <div class="supplier-container">
        <div class="credit-limit d-flex align-items-center justify-content-between">
          <img
            class="company-logo"
            src="/new-assets/img/2סאסאטק.png"
            alt=""
          />
          <img
            class="company-logo"
            src="/new-assets/img/shaniv-logo.png"
            alt=""
          />
          <h2 class="fs20">{{ $t('CART.ORDER_SUMMARY') }}</h2>
        </div>

        <div class="discount">
          <div class="flex fs14">
            <p>{{ $t('CART.ORDER_VALUE') }}: </p>
            <p class="weight-700">
              ₪{{ VATPRICE }}
            </p>
          </div>
          <div v-if="DISCOUNT_AMOUNT && DISCOUNT_AMOUNT > 0" class="flex fs14 color-green3 weight-700">
            <p>{{ $t('CART.SAVINGS') }}: </p>
            <p>₪{{ DISCOUNT_AMOUNT }}</p>
          </div>
        </div>
        <div class="order-summary fs14">
          <div class="flex">
            <div>
              <p class="m-b-20">{{ $t('CART.ORDER_SUMMARY') }}: </p>
              <p v-if="customerData && Object.values(customerData).length" class="m-b-5 cursor-pointer" @click="openShanivCustomerAgentModal">
                {{ $t('GIFTCARD_PAYMENT_RESULT.AGENT') }}:
                <span><b>{{ customerAgentNames() }}</b></span>
              </p>
              <p class="m-b-5">{{ $t('CART.DAY_ORDER_ARRIVES') }}: <b>{{ dueDate }}</b></p>
            </div>
            <div class="fs18 weight-700">
              <p>₪{{ DISCOUNT_VATPRICE }}</p>
            </div>
          </div>
        </div>
      </div>
      <slot name="actionBtn"></slot>
    </div>
    <ShanivCustomerAgentModal></ShanivCustomerAgentModal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import ShanivCustomerAgentModal from "../ShanivCustomerAgentModal.vue";

export default {
  name: 'PaymentSummary',
  components: {
    ShanivCustomerAgentModal
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      paymentDetails: (state) => state.cartSystem.shanivPaymentDetails
    }),
    orderId() {
      return this.$route.params.id;
    },
    paymentSummary() {
      let paymentSummary = null;

      if (this.paymentDetails && this.paymentDetails.paymentSummary) {
        paymentSummary = {...this.paymentDetails.paymentSummary};
      }

      return paymentSummary;
    },
    VATPRICE() {
      let VATPRICE = 0;

      if (this.paymentSummary && this.paymentSummary.VATPRICE) {
        VATPRICE = this.paymentSummary.VATPRICE;
      }

      return VATPRICE;
    },
    DISCOUNT_VATPRICE() {
      let DISCOUNT_VATPRICE = 0;

      if (this.paymentSummary && this.paymentSummary.DISCOUNT_VATPRICE) {
        DISCOUNT_VATPRICE = this.paymentSummary.DISCOUNT_VATPRICE;
      }

      return DISCOUNT_VATPRICE;
    },
    DISCOUNT_AMOUNT() {
      let amount = 0;

      if (this.VATPRICE && this.DISCOUNT_VATPRICE) {
        amount = this.VATPRICE - this.DISCOUNT_VATPRICE;
      }

      amount = Math.round((amount + Number.EPSILON) * 100) / 100;
      return amount;
    },
    dueDate() {
      let dueDate = null;

      if (this.paymentSummary && this.paymentSummary.dueDate) {
        dueDate = moment(this.paymentSummary.dueDate).format('DD/MM/YYYY dddd');
      } else {
        dueDate = 'N/A';
      }

      return dueDate;
    },
  },
  async created() {
      // load payment details
      await this.getPaymentDetails({id: this.orderId});
  },
  methods: {
    ...mapActions('cartSystem', {
      getPaymentDetails: 'getPaymentDetails',
    }),
    openShanivCustomerAgentModal() {
      this.$modal.show('shanivCustomerAgentModal');
  },
  },
};
</script>

<style lang="scss" scoped>
  .summary-container {
    width: 46%;
    margin-top: 20px;
    .company-logo {
      max-width: 100px;
      width: 100%;
      height: auto;
    }
    .summary-box {
      border: 1px solid #707070;
      border-radius: 6px;
      padding: 10px;

      .supplier-container {
        .flex {
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          margin-bottom: 0.5rem;
        }

        .credit-limit,
        .discount {
          border-bottom: 1px solid #707070;
          padding: 8px 15px;
        }

        .discount,
        .order-summary {
          padding: 8px 15px 0;
        }
      }
    }
  }
</style>